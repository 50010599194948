<template>
  <div class="under-development">
    <div>
      <p class="text-center">
        <template v-if="svg">
          <img :src="svg" style="width: 30%;">
        </template>
        <template v-else>
          <img src="@/assets/img/svgs/blank_canvas.svg" style="width: 30%;">
        </template>
      </p>
      <h3 class="font-km-bold text-center font-secondary">{{ description }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnderDevelopment',
  props: {
    box: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'កំពុងស្ថិតក្នុងការអភិវឌ្ឍន៍នៅឡើយ'
    },
    description: {
      type: String,
      default: 'កំពុងស្ថិតក្នុងការអភិវឌ្ឍន៍នៅឡើយ'
    },
    svg: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
.under-development {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
}
</style>
