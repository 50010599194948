<template>
  <div class="col-md-12" v-if="project">
    <div class="row">
      <div class="col-md-12">
        <p>
          <strong>
            <i class="fa fa-building-o"></i> {{ $t('string.aboutBuildingInformation') }}
          </strong>
        </p>
      </div>
    </div>
    <template v-if="project.buildings && project.buildings.length">
      <template v-for="(building, key) in project.buildings">
        <div class="building" :key="key">
          <div class="row">
            <div class="col-md-12">
              <p>{{ key + 1 }} - {{ building.name }}</p>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-xs-6">
                  <p>
                    {{ $t('string.builtAt') }} <strong>{{ building.year || '-' }}</strong>
                  </p>
                </div>
                <div class="col-xs-6">
                  <p>
                    {{ $t('string.usageFunction') }} <strong>{{ building.usage_function || '-' }}</strong>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-6">
                  <p>
                    {{ $t('string.totalConstructionArea') }} <strong>{{ building.total_construction_area || '-' }}</strong>
                  </p>
                </div>
                <div class="col-xs-6">
                  <p>
                    {{ $t('string.totalConstructionAreaPractical') }} <strong>{{ building.total_construction_area_practical || '-' }}</strong>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-6">
                  <p>
                    {{ $t('string.totalFloors') }} <strong>{{ building.total_floors || '-' }}</strong>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <table cellspacing="0" cellpadding="0" border="1" class="table-custom">
                <thead>
                <tr>
                  <th>{{ $t('string.floorName') }}</th>
                  <th>{{ $t('table.totalConstructionArea') }}</th>
                  <th>{{ $t('table.totalConstructionAreaPractical') }}</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="building.floors && building.floors.length">
                  <tr v-for="(floor, key) in building.floors" :key="key">
                    <td>{{ floor.name }}</td>
                    <td>{{ floor.total_construction_area }}</td>
                    <td>{{ floor.total_construction_area_practical }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="3">
                      <p class="text-center">
                        {{ $t('string.noResult') }}
                      </p>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="alert alert-warning">{{ $t('string.noResult') }}</div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ProjectBuildingDetail',
  props: {
    project: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>
.building {
  padding: 10px;
  border-radius: 4px;
  background-color: #f2f2f2;
  margin-bottom: 10px;
}

table.table-custom {
  width: 100%;
  margin-top: 10px;
}

table.table-custom thead tr {
  background-color: #b9b9b9 !important;
}

table.table-custom thead tr th,
table.table-custom tbody tr td {
  padding: 4px;
}
</style>
