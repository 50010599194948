<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="project-title">{{ project.construction_type ? project.construction_type['name_' + $i18n.locale] : $t('string.na') }}</h4>
      <p><i class="fa fa-user"></i> {{ owners || $t('string.na' )}}</p>
      <p>
        <i class="fa fa-tag"></i>
        <template v-if="project.project_type">
          {{ project.project_type['name_' + $i18n.locale] }}
        </template>
      </p>
    </div>
    <div class="col-md-12 mb-2">
      <GmapMap
        :center="{lat:project.lat, lng:project.lng}"
        :zoom="15"
        map-type-id="terrain"
        style="width: 100%; height: 320px">
        <GmapMarker
          :icon="{url: '/img/markers/blue20x20.png'}"
          :position="{lat:project.lat, lng:project.lng}"/>
      </GmapMap>
    </div>
    <div class="col-md-12">
      <div class="row" v-if="project.detail_values">
        <div class="col-md-12">
          <table class="table table-bordered table-striped">
            <tbody>
            <tr v-for="(item, key) in project.detail_values"
                :key="key">
              <td>{{ item['label_' + $i18n.locale] }}</td>
              <td>{{ item['value_' + $i18n.locale] }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <project-building-detail :project="project"/>
    <div class="col-md-12">
      <div class="row mb-2 mt-2" v-viewer>
        <div transition-duration="0.3s"
             item-selector=".item"
             class="col-md-12">
          <div class="row">
            <div v-masonry-tile
                 class="col-md-2 col-xs-3 mb-2"
                 :key="index"
                 v-for="(item, index) in project.cci_images">
              <img :src="`${baseUrl}/${item.src}`"
                   class="img-responsive thumbnail"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectBuildingDetail from '@/components/ProjectBuildingDetail'
export default {
  name: 'ProjectDetail',
  components: { ProjectBuildingDetail },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      owners: null
    }
  },
  watch: {
    '$i18n.locale' () {
      this.setOwner()
    }
  },
  methods: {
    setOwner () {
      this.owners = null
      if (this.project && this.project.owners && Array.isArray(this.project.owners)) {
        this.owners = this.project.owners
          .map((item) => item['name_' + this.$i18n.locale])
          .join(', ')
      }
    }
  },
  mounted () {
    this.setOwner()
  }
}
</script>

<style scoped>
.project-title {
  line-height: 0;
  padding-bottom: 10px;
}
</style>
